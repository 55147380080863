import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import "./css/menuTop.css";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../../../../img/logo.webp";

export default function Menutop() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  const [active, setMode] = useState(false);
  const ToggleMode = () => {
    setMode(!active)
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Login Status   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [loginStatus, getLoginStatus] = useState("");

  useEffect(() => {
    Axios.get(`${apiUrl}/api/session`).then((response) => {
      if (response.data.loggedIn === true) {
        getLoginStatus("user : " + response.data.user[0].userName);
      } else {
        getLoginStatus("LogIn");
      }
    });
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Settings - Menu Top - RQ   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [settingsReviewList, setSettingsReviewList] = useState({});
  const [menuReviewList, setMenuReviewList] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const fetchSettingsList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getSettingsCache`);
    return response.data[0]
  };

  const fetchMenuList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getMenuTopCache`);
    return response.data;
  };

  const { data: settingsReviewListData, isLoading: isSettingsListLoading } = useQuery(
    ["settingsList"],
    fetchSettingsList
  );

  const { data: menuReviewListData, isLoading: isMenuListLoading } = useQuery(
    ["menuList"],
    fetchMenuList
  );

  useEffect(() => {
    if (settingsReviewListData) {
      setSettingsReviewList(settingsReviewListData);
    }
  }, [settingsReviewListData]);

  useEffect(() => {
    if (menuReviewListData) {
      setMenuReviewList(menuReviewListData);
    }
  }, [menuReviewListData]);

  if (isSettingsListLoading || isMenuListLoading) {
    // return <div></div>;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <div className="menuTop">

      <div className="logo">
        <img
          className={active ? "miconon" : "miconon"}
          onClick={ToggleMode}
          data-aos={"fade-down"}
          data-aos-duration={"800"}
          data-aos-offset={"100"}
          data-aos-delay={"100"}
          width={"436px"}
          height={"342px"}
          alt={settingsReviewList.appName}
          src={logo}
        />
        <Link to={process.env.PUBLIC_URL + "/"}>
          <img
            data-aos={"fade-down"}
            data-aos-duration={"800"}
            data-aos-offset={"100"}
            data-aos-delay={"100"}
            width={"436px"}
            height={"342px"}
            alt={settingsReviewList.appName}
            src={logo}
          />
        </Link>
      </div>
      <nav className={active ? "menuopen" : "menuclose"}>
        <ul
          key="siteHead"
          data-aos={"fade-right"}
          data-aos-duration={"800"}
          data-aos-offset={"100"}
          data-aos-delay={"50"}
          className="left"
        >
          <li><Link to={process.env.PUBLIC_URL + "/"}>{settingsReviewList.appName}</Link></li>
          <li><a href={settingsReviewList.appBaseUrl + "/login"}>{loginStatus}</a></li>
        </ul>
        <ul
          data-aos={"fade-left"}
          data-aos-duration={"800"}
          data-aos-offset={"100"}
          data-aos-delay={"50"}
          className="right"
        >
          {menuReviewList.map((val) => {
            return <li key={"topmenu" + val.id}>
              <Link to={process.env.PUBLIC_URL + val.menuTopLink}>{val.menuTopTitle}</Link>
            </li>
          })}
        </ul>
      </nav>
    </div>
  );

}