import React from "react";

import "./css/1/Cli.css";

import Loading from "../settings/Loading";
import Menutop from "./components/modules/menuTop";
import Contactform from "./components/modules/contactForm";
import Footer from "./components/modules/footer";

export default function NScontact() {

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  document.title = "Contact";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <section className="cli NScontact">
      <div id="progress"></div>
      <Loading />
      <Menutop />
      <Contactform />
      <Footer />
    </section>
  );

}