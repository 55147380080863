import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import "./css/singleCategoryList.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Singlecategorylist() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Single Category   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  // const [isParsed, setIsParsed] = useState(false);

  let { categoryid } = useParams();
  const [get, setPost] = useState({ id: "", categoryName: "", url: "" });
  const [categorySiteName, getCategorySiteName] = useState("");
  const [categoryBaner, getCategoryBaner] = useState("");

  useEffect(() => {
    Axios.get(`${apiUrl}/api/getCategory/${categoryid}`).then((response) => {
      setPost({
        id: response.data[0].id,
        categoryName: response.data[0].categoryName,
        mediaUrl: response.data[0].mediaUrl,
        url: response.data[0].url,
      });
      getCategorySiteName(response.data[0].categoryName);
      getCategoryBaner(`${apiUrl}/uploads/images/optimized/${response.data[0].mediaUrl}`);
      // setIsParsed(true);
    });
  }, [categoryid]);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Settings - Post List - RQ  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [settingsReviewList, setSettingsReviewList] = useState({});
  const [postReviewList, setPostReviewList] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const fetchSettingsList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getSettingsCache`);
    return response.data[0]
  };

  const fetchPostList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getPostListCache`);
    return response.data;
  };

  const { data: settingsReviewListData, isLoading: isSettingsListLoading } = useQuery(
    ["settingsList"],
    fetchSettingsList
  );

  const { data: postReviewListData, isLoading: isPostListLoading } = useQuery(
    ["postList"],
    fetchPostList
  );

  useEffect(() => {
    if (settingsReviewListData) {
      setSettingsReviewList(settingsReviewListData);
    }
  }, [settingsReviewListData]);

  useEffect(() => {
    if (postReviewListData) {
      setPostReviewList(postReviewListData);
    }
  }, [postReviewListData]);

  if (isSettingsListLoading || isPostListLoading) {
    // return <div></div>;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  document.title = categorySiteName + ' - ' + settingsReviewList.appName;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <div className="singleCategoryList">

      <h2>{categorySiteName}</h2>

      <div className="listFormat">

        <div
          data-aos={"fade-right"}
          data-aos-duration={"1400"}
          data-aos-offset={"100"}
          data-aos-delay={"100"}
          className="img"
          style={{ backgroundImage: `url(${categoryBaner})` }}
        ></div>

        <div className="posts">
          {postReviewList
            .filter((val) => val.postCategory === get.categoryName)
            .map((val) => {
              return <article
                data-aos={"fade-up"}
                data-aos-duration={"800"}
                data-aos-offset={"100"}
                data-aos-delay={"100"}
                className={val.postCategory} key={val.id}
              >
                <span className="card">
                  <Link title={val.postTitle} to={`/post/${val.url}`}><div className="thumb" style={{ backgroundImage: `url(${apiUrl}/uploads/images/thumbnail/${val.imageDataBaner})` }}></div></Link>
                  <h3>{val.postTitle}</h3>
                </span>
              </article>
            })}
        </div>

      </div>

    </div>
  );

}