import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import "./css/categoryList.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Categorylist() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Category List - RQ   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [categoryReviewList, setCategoryReviewList] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const fetchCategoryList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getCategorylistCache`);
    return response.data;
  };

  const { data: categoryReviewListData, isLoading: isCategoryListLoading } = useQuery(
    ["categoryList"],
    fetchCategoryList
  );

  useEffect(() => {
    if (categoryReviewListData) {
      setCategoryReviewList(categoryReviewListData);
    }
  }, [categoryReviewListData]);

  if (isCategoryListLoading) {
    // return <div></div>;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <div className="categoryList">

      <h2
        data-aos={"fade-up"}
        data-aos-duration={"800"}
        data-aos-offset={"50"}
        data-aos-delay={"50"}
      >Co fotografujemy</h2>
      <div className="posts">
        {categoryReviewList.map((val) => {
          return <article
            data-aos={"fade-up"}
            data-aos-duration={"800"}
            data-aos-offset={"100"}
            data-aos-delay={"100"}
            className={val.categoryName} key={val.id}
          >
            <span className="card">
            <Link title={val.categoryName} to={`/category/${val.url}`}><div className="thumb" style={{ backgroundImage: `url(${apiUrl}/uploads/images/thumbnail/${val.mediaUrl})` }}></div></Link>
            <h3>{val.categoryName}</h3>
            </span>
          </article>
        })}
      </div>
      <p
        data-aos={"fade-up"}
        data-aos-duration={"800"}
        data-aos-offset={"150"}
        data-aos-delay={"150"}
      >Sesje fotograficzne bez stresu</p>

    </div>
  );

}