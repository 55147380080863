import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import "./css/footer.css";

export default function Footer() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Settings - RQ  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [settingsReviewList, setSettingsReviewList] = useState({});

  const fetchSettingsList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getSettingsCache`);
    return response.data[0]
  };

  const { data: settingsReviewListData, isLoading: isSettingsListLoading } = useQuery(
    ["settingsList"],
    fetchSettingsList
  );

  useEffect(() => {
    if (settingsReviewListData) {
      setSettingsReviewList(settingsReviewListData);
    }
  }, [settingsReviewListData]);

  if (isSettingsListLoading) {
    // return <div></div>;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <div className="footer">

      <div className="info">
        <span className="f1"><Link to={process.env.PUBLIC_URL + "/"}>{settingsReviewList.appName}</Link></span>
        <span className="f2"></span>
        <span className="f3"></span>
        <span className="f4"></span>
      </div>

      <span className="copyright">Nibiru Studio</span>
    </div>
  );

}