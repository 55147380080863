import React, { useState } from "react";
import Axios from "axios";
import "./css/contactForm.css";

export default function Contactform() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Contact Form   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [nrtel, setNrtel] = useState("");
  const [subject, setSubject] = useState("");
  const [date, setDate] = useState("");
  const [message, setMessage] = useState("");

  const submitContactForm = async () => {
    Axios.post(`${apiUrl}/api/sendMessage`, {
      name: name,
      surname: surname,
      email: email,
      nrtel: nrtel,
      subject: subject,
      date: date,
      message: message,
    });
    alert("Wiadomość została wysłana.");
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <div className="form contactForm">
      <form>

        <label htmlFor="name">Imię*</label>
        <input
          id="name"
          type="text"
          name="name"
          placeholder="Imię:"
          onChange={(e) => {
            setName(e.target.value)
          }}
        />

        <label htmlFor="surname">Nazwisko</label>
        <input
          id="surname"
          type="text"
          name="surname"
          placeholder="Nazwisko:"
          onChange={(e) => {
            setSurname(e.target.value)
          }}
        />

        <label htmlFor="email">E-mail*</label>
        <input
          id="email"
          type="text"
          name="email"
          placeholder="E-mail:"
          onChange={(e) => {
            setEmail(e.target.value)
          }}
        />

        <label htmlFor="nrtel">Nr. Tel</label>
        <input
          id="nrtel"
          type="number"
          name="nrtel"
          placeholder="Nr. Tel:"
          onChange={(e) => {
            setNrtel(e.target.value)
          }}
        />

        <label htmlFor="subject">Rodzaj sesji fotograficznej:</label>
        <select
          name="subject"
          onChange={(e) => { setSubject(e.target.value) }}>
          <option key="Temat" value="all">Wybierz temat</option>
          <option key="Chrzciny" value="chrzciny">Chrzciny</option>
        </select>

        <label htmlFor="surname">Zapytaj o dokładny termin</label>
        <input
          id="date"
          type="date"
          name="date"
          placeholder="Date:"
          onChange={(e) => {
            setDate(e.target.value)
          }}
        />

        <label htmlFor="message">Wiadomość*</label>
        <textarea
          rows="10"
          name="message"
          className="message"
          placeholder="Wiadomość:"
          onChange={(e) => {
            setMessage(e.target.value)
          }}></textarea>
        <button
        onClick={submitContactForm}
        >
          Submit
        </button>

      </form>
    </div>
  );

}