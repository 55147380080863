import React from "react";

import "./css/1/Cli.css";

import Loading from "../settings/Loading";
import Menutop from "./components/modules/menuTop";
import Topcarousel from "./components/modules/topCarousel";
import Postlist from "./components/modules/postList";
import Categorylist from "./components/modules/categoryList";
import Footer from "./components/modules/footer";

export default function NSindex() {

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  document.title = "Home";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <section className="cli NSindex">
      <div id="progress"></div>
      <Loading />
      <Menutop />
      <Topcarousel />
      <Postlist />
      <Categorylist />
      <Footer />
    </section>
  );

}