import React from "react";

import "./css/1/Cli.css";

import Loading from "../settings/Loading";
import Menutop from "./components/modules/menuTop";
import Singlepost from "./components/modules/singlePost";
import Footer from "./components/modules/footer";

export default function NSpost() {

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <section className="cli NSpost">
      <div id="progress"></div>
      <Loading />
      <Menutop />
      <Singlepost />
      <Footer />
    </section>
  );

}