import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import "./css/singlePost.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Singlepost() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Single Post //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [isParsed, setIsParsed] = useState(false);

  let { postid } = useParams();
  const [post, setPost] = useState({ id: "", postTitle: "", url: "", editorHtml: "" });
  const [postSiteName, getPostSiteName] = useState("");
  // const [postBaner, getPostBaner] = useState("");

  useEffect(() => {
    Axios.get(`${apiUrl}/api/getPost/${postid}`).then((response) => {
      setPost({
        id: response.data[0].id,
        postTitle: response.data[0].postTitle,
        url: response.data[0].url,
        editorHtml: response.data[0].editorHtml,
      });
      getPostSiteName(response.data[0].postTitle);
      // getPostBaner(`${apiUrl}/uploads/images/optimized/${response.data[0].imageDataBaner}`);
      setIsParsed(true);
    });
  }, [postid]);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Settings - Gallery - RQ //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [settingsReviewList, setSettingsReviewList] = useState({});
  const [galleryReviewList, setGalleryReviewList] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const fetchSettingsList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getSettingsCache`);
    return response.data[0]
  };

  const fetchGalleryList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getGalleryCache`);
    return response.data;
  };

  const { data: settingsReviewListData, isLoading: isSettingsListLoading } = useQuery(
    ["settingsList"],
    fetchSettingsList
  );

  const { data: galleryReviewListData, isLoading: isGalleryListLoading } = useQuery(
    ["galleryList"],
    fetchGalleryList
  );

  useEffect(() => {
    if (settingsReviewListData) {
      setSettingsReviewList(settingsReviewListData);
    }
  }, [settingsReviewListData]);

  useEffect(() => {
    if (galleryReviewListData) {
      setGalleryReviewList(galleryReviewListData);
    }
  }, [galleryReviewListData]);

  if (isSettingsListLoading || isGalleryListLoading) {
    // return <div></div>;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  document.title = postSiteName + ' - ' + settingsReviewList.appName;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <div className="singlePost">

      <div className="post format">

        {/* <img alt={post.postTitle} src={postBaner} /> */}

        <h2>{post.postTitle}</h2>

        {isParsed ? Parse(post.editorHtml) : null}

        <div className="gallery">
          {galleryReviewList
            .filter((val) => val.galleryName === postSiteName)
            .map((val) => {
              return <div className="mediaitem fadeload" key={val.id}>
                <div className="thumb" style={{ backgroundImage: `url(${apiUrl}/uploads/images/thumbnail/${val.mediaUrl})` }}>
                </div>
              </div>
            })}
        </div>

      </div>

    </div>
  );

}