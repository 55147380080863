import React from "react";
import ReactDOM from "react-dom";
import Axios from "axios";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Axios.defaults.withCredentials = true;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();