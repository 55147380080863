import React, { useEffect } from "react";
import "./css/topCarousel.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Topcarousel() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <div className="topCarousel">

      <div className="carousel">

        <article>
          <div
            data-aos={"zoom-in"}
            data-aos-duration={"600"}
            data-aos-offset={"200"}
            data-aos-delay={"50"}
            className="thumb"></div>

          <div
            data-aos={"zoom-in"}
            data-aos-duration={"600"}
            data-aos-offset={"200"}
            data-aos-delay={"50"}
            className="thumb"></div>

          <div
            data-aos={"zoom-in"}
            data-aos-duration={"600"}
            data-aos-offset={"200"}
            data-aos-delay={"50"}
            className="thumb"></div>

          <div
            data-aos={"zoom-in"}
            data-aos-duration={"600"}
            data-aos-offset={"200"}
            data-aos-delay={"50"}
            className="thumb"></div>
        </article>

      </div>

    </div>
  );

}