import React, { useEffect } from "react";
import logo from "../../img/logo.webp";

export default function Loading() {

  const [componentsLoaded, setComponentsLoaded] = React.useState(false);

  useEffect(() => {
    const preload = document.getElementById("preload");
    if (preload && preload.parentNode) {
      const componentsLoadedTimeout = setTimeout(() => {
        setComponentsLoaded(true);
      }, 0);
      setTimeout(() => {
        if (componentsLoaded) {
          preload.parentNode.removeChild(preload);
          clearTimeout(componentsLoadedTimeout);
        }
      }, 1000);
    }
  }, [componentsLoaded]);

  return (
    <div id="preload" className="preload">
      <img src={logo} alt="Loading" />
    </div>
  );

};