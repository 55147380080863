import React from "react";

import "./css/Reset.css";
import "./css/Scale.css";
import "./css/Load.css";
import "./views/public/css/1/NScliGlobal.css";
import "./font/Font.css";

import ScrollToTop from "./views/settings/ScrollToTop";

import NSindex from "./views/public/NSindex";
import NScategory from "./views/public/NScategory";
import NSpost from "./views/public/NSpost";
import NScontact from "./views/public/NScontact";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

export default function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>

          <Route
            path="/"
            exect
            element={<NSindex />}
          />

          <Route
            path="/category/:categoryid"
            element={<NScategory />}
          />

          <Route
            path="/post/:postid"
            element={<NSpost />}
          />

          <Route
            path="/contact"
            element={<NScontact />}
          />

        </Routes>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}