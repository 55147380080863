import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import "./css/postList.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Postlist() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Post List - RQ //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [postReviewList, setPostReviewList] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const fetchPostList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getPostListCache`);
    return response.data;
  };

  const { data: postReviewListData, isLoading: isPostListLoading } = useQuery(
    ["postList"],
    fetchPostList
  );

  useEffect(() => {
    if (postReviewListData) {
      setPostReviewList(postReviewListData);
    }
  }, [postReviewListData]);

  if (isPostListLoading) {
    // return <div></div>;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <div className="postList">

      <h2
        data-aos={"fade-up"}
        data-aos-duration={"800"}
        data-aos-offset={"50"}
        data-aos-delay={"50"}
      >Realizacje</h2>
      <div className="posts">
        {postReviewList.map((val) => {
          return <article
            data-aos={"fade-up"}
            data-aos-duration={"800"}
            data-aos-offset={"100"}
            data-aos-delay={"100"}
            className={val.postCategory} key={val.id}
          >
            <span className="card">
              <Link title={val.postTitle} to={`/post/${val.url}`}><div className="thumb" style={{ backgroundImage: `url(${apiUrl}/uploads/images/thumbnail/${val.imageDataBaner})` }}></div></Link>
              <h3>{val.postTitle}</h3>
            </span>
          </article>
        })}
      </div>
      <p
        data-aos={"fade-up"}
        data-aos-duration={"800"}
        data-aos-offset={"150"}
        data-aos-delay={"150"}
      >Sesje fotograficzne bez stresu</p>

    </div>
  );

}